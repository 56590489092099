import AnalystCard from 'src/components/Analyst/AnalystCard';
import Grid from 'src/components/Grid';
import Link from 'src/components/Link';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import { above, below } from 'src/components/global/mediaqueries';
import { useStaticQuery, graphql } from 'gatsby';

const AnalystCardWrapper = styled('div')`
    height: 100%;
`;

const AnalystsGridWrapper = styled('div')`
    // This selects the singlepost module and puts row-reverse on every other module.

    ${below.tablet_s} {
        ${theme.spacing.mobile._12('padding-left')};
        ${theme.spacing.mobile._12('padding-right')};
        border-bottom: 1px solid ${theme.color.black};
    }

    ${above.tablet_s} {
        ${theme.spacing.tablet._16('padding-left')};
        ${theme.spacing.tablet._16('padding-right')};

        ${theme.spacing.desktop._16('padding-left')};
        ${theme.spacing.desktop._16('padding-right')};

        .module-wrapper {
            &:nth-of-type(4n + 2) {
                .module__singlepost {
                    flex-direction: row-reverse;
                }
            }
        }
        // This adds border and padding between cards
        .analyst-card {
            ${theme.spacing.mobile._0('padding-top')};
            ${theme.spacing.tablet._16('padding-top')};
            ${theme.spacing.desktop._16('padding-top')};
            border-left: solid 1px black;
            &:nth-of-type(-n + 3) {
                padding: 0;
            }
            &:first-of-type {
                & > div {
                    border: none;
                }
            }
            &:nth-of-type(3n-2) {
                border-left: none;
            }
        }
    }
`;

const EmptyCard = styled('div')`
    ${below.tablet_s} {
        display: none;
    }
    width: 100%;
    height: 100%;
    ${theme.spacing.mobile._0('padding-left')};
    ${theme.spacing.mobile._0('padding-right')};

    ${theme.spacing.tablet._12('padding-left')};
    ${theme.spacing.tablet._12('padding-right')};

    ${theme.spacing.desktop._16('padding-left')};
    ${theme.spacing.desktop._16('padding-right')};

    & > div {
        width: 100%;
        height: 100%;
        border-bottom: 1px solid ${theme.color.black};
    }
`;

const { trimUrl, matchUrlToImage } = require('api/Images/MatchModuleImages');

const Analysts = () => {
    const { allCmsApiAnalysts, allCmsApiImages } = useStaticQuery(
        graphql`
            query {
                allCmsApiAnalysts {
                    edges {
                        node {
                            id
                            acf_data
                            post_title
                        }
                    }
                }
                allCmsApiImages(filter: { id: { ne: "dummy" } }) {
                    edges {
                        node {
                            id
                            fields {
                                image {
                                    localFile {
                                        childImageSharp {
                                            small: fluid(maxWidth: 750, quality: 80) {
                                                aspectRatio
                                                base64
                                                originalImg
                                                sizes
                                                src
                                                srcSet
                                                srcWebp
                                                srcSetWebp
                                            }
                                            medium: fluid(maxWidth: 1000, quality: 80) {
                                                aspectRatio
                                                base64
                                                originalImg
                                                sizes
                                                src
                                                srcSet
                                                srcWebp
                                                srcSetWebp
                                            }
                                            large: fluid(maxWidth: 2000, quality: 80) {
                                                aspectRatio
                                                base64
                                                originalImg
                                                sizes
                                                src
                                                srcSet
                                                srcWebp
                                                srcSetWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    const allImages = allCmsApiImages.edges;
    const num = allCmsApiAnalysts.edges.length / 3;
    let emptyCards;

    if (num % 1 === 0) {
        emptyCards = 0;
    } else if (((allCmsApiAnalysts.edges.length + 1) / 3) % 1 === 0) {
        emptyCards = 1;
    } else {
        emptyCards = 2;
    }

    const emptyCardsArr = new Array(emptyCards).fill(null);
    return (
        <AnalystsGridWrapper>
            <Grid columns={3}>
                {allCmsApiAnalysts.edges.map((analyst, index) => {
                    const acfData = JSON.parse(analyst.node.acf_data);
                    const imageUrl = acfData.photo;
                    const image = matchUrlToImage({
                        imageUrl: trimUrl(imageUrl),
                        allImages,
                    });
                    return (
                        <div key={index} className="analyst-card">
                            <AnalystCardWrapper>
                                <Link src={`${acfData.permalink}`}>
                                    <AnalystCard
                                        image={image}
                                        heading={analyst.node.post_title}
                                        tag={acfData.position}
                                        link={`${acfData.permalink}`}
                                        linkText="Read more"
                                    />
                                </Link>
                            </AnalystCardWrapper>
                        </div>
                    );
                })}
                {emptyCards > 0 &&
                    emptyCardsArr.map((_, index) => {
                        return (
                            <div key={index} className="analyst-card">
                                <AnalystCardWrapper>
                                    <EmptyCard>
                                        <div />
                                    </EmptyCard>
                                </AnalystCardWrapper>
                            </div>
                        );
                    })}
            </Grid>
        </AnalystsGridWrapper>
    );
};

export default Analysts;
